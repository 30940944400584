import { AES, enc } from 'crypto-js'

const password = process.env.REACT_APP_KEY_AES

export const encriptFE = (data) => {
    return AES.encrypt(data, password)
}

export const decrypt = (data) => {
    const bytes = AES.decrypt(data, password)
    const decrypted = bytes.toString(enc.Utf8)
    return decrypted
}